export interface State {
  roundar: {
    on: boolean;
  };
}

export const initialState: State = {
  roundar: {
    on: false
  }
};

export const getUserPreferenceRoundar = (state: State) => state.roundar;
