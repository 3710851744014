import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "unitListFilter",
  pure: false,
})
export class UnitListFilterPipe implements PipeTransform {
  transform(unitsList, ...filterArgs: any) {
    if (!unitsList) return;

    const saFilters = filterArgs[0];
    let updatedUnitsList = unitsList.sort((a, b) =>
      a.hospitalInfo?.name.localeCompare(b.hospitalInfo?.name)
    );

    const hospital = saFilters.controls.hospital.value?.toLowerCase(),
      physician = saFilters.controls.physician.value?.toLowerCase(),
      nurse = saFilters.controls.nurse.value?.toLowerCase();

    if (hospital) {
      updatedUnitsList = updatedUnitsList.filter((unit) =>
        unit?.hospitalInfo?.name?.toLowerCase()?.includes(hospital)
      );
    }

    if (physician) {
      updatedUnitsList = updatedUnitsList.filter((unit) =>
        unit?.docAssigned?.name?.toLowerCase()?.includes(physician)
      );
    }

    if (nurse) {
      updatedUnitsList = updatedUnitsList.filter(
        (unit) =>
          unit?.npAssigned?.name?.toLowerCase()?.includes(nurse) ||
          unit?.rnAssigned?.name?.toLowerCase()?.includes(nurse) ||
          unit?.ccrnAssigned?.name?.toLowerCase()?.includes(nurse)
      );
    }

    // sorting uni-list : all assigned on top and all un-assigned on bottom
    updatedUnitsList = updatedUnitsList.sort(
      (a, b) => b.assignButtonText.length - a.assignButtonText.length
    );

    return updatedUnitsList;
  }
}
