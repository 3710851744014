import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[appTextHighlight]",
})
export class TextHighlightDirective {
  @Input() set appTextHighlight(textMatch: string) {
    textMatch = textMatch?.trim();
    const nativeElement = this.ele.nativeElement;
    if (
      textMatch?.length &&
      nativeElement.innerText.toLowerCase().includes(textMatch)
    ) {
      nativeElement.classList.add("highlight-text");
      return;
    }
    nativeElement.classList.remove("highlight-text");
  }

  constructor(private ele: ElementRef) {}
}
